<template>
  <main id="content">
    <h1>
      <a href="/">money<span id="title-highlight">blocks</span></a>
    </h1>
    <div id="nav">
      <!-- <router-link to="/">Home</router-link> -->
      <router-link to="/about">About</router-link>
      <a href="https://coryeth.substack.com/" target="_blank">Blog</a>
      <router-link to="/contact">Contact</router-link>
      <!-- <a href="https://twitter.com/cory_eth" target="_blank">@cory_eth</a> -->
      <a href="https://twitter.com/ethburnbot" target="_blank"
        ><span id="no-bold">🔥</span> ethburnbot</a
      >
      <!-- |
      <router-link to="/userInput">User Input</router-link>
      |
      <router-link to="/userSelect">User Select</router-link>
      |
      <router-link to="/web3">web3</router-link> -->
    </div>
    <router-view />
  </main>
</template>

<style lang="scss">
$theme_green: #67a142;

body {
  background: #e7e7e7;
  display: flex;
  flex-direction: column;

  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}
#content {
  h1 {
    font-size: 5em;
    a {
      color: inherit;
      text-decoration: none;
    }
  }
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#no-bold {
  font-weight: normal;
}
#nav {
  padding: 30px;

  a {
    color: #2c3e50;
    font-weight: bold;
    margin-right: 3em;

    text-decoration: none;

    &.router-link-exact-active {
      color: $theme_green;
    }
  }
}

#title-highlight {
  color: $theme_green;
}
</style>
